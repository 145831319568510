import React from 'react';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';

const IndexPage = () => (
	<Layout>
		<SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
		<h1>Hi there!</h1>
		<p>Please visit Easy Agent Pro for more information.</p>
		<p>
			<a href="https://easyagentpro.com">Easy Agent Pro</a>
		</p>

		<p>
			<a href="https://www.easyagentpro.com/terms-use/">Easy Agent Pro Terms</a>
		</p>
		<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
			<Image />
		</div>
	</Layout>
);

export default IndexPage;
